import { Details } from "@material-ui/icons";
import Course from "../../views/apps/inbox/Course";
import dateDifference from "../../views/utility/dateDifference";

const INIT_STATE = {
  inbox: [],
  adhocTaskList: [],
  adhocTask: {},
  adhocLoaded: false,
  adhocSuccess: "",
  adhocModelLoaded: true,
};

const InboxReducer = (state = INIT_STATE, action) => {
  //console.log("state", state);
  switch (action.type) {
    case "FETCH_INBOX":
      // const emaildata = filterEmails(action.inbox.data);
      const emaildata = action.inbox.data;
      //console.log("Inbox", action.inbox.data, emaildata);
      return {
        ...state,
        Emails: emaildata,
        loaded: true,
        success: "",
      };

    case "FETCH_COMPLETE":
      console.log("complete", action.complete.data);
      return {
        ...state,
        complete: action.complete.data,
        loaded: true,
        success: "",
      };

    case "OPEN_INBOX_ITEM":
      console.log("item in reducer", action.mainItem);
      return {
        ...state,
        itemid: action.itemid,
        itemtype: action.itemtype,
        itemkitchen: action.itemkitchen,
        mainItem: action.mainItem,
        programId: action.programId,
      };
    case "FETCH_USER_EARNING":
      console.log("action ", action.earning);
      let earning1;
      if (action.earning.length > 0) {
        earning1 = changeDataFormat(action.earning[0].activity);
      } else {
        earning1 = action.earning;
      }

      return {
        ...state,
        earning: earning1,
        loaded: true,
        success: "",
      };
    case "EARNING_CONFIG":
      return {
        ...state,
        config: action.config,
      };
    case "FETCH_ADHOC_TASK_LIST":
      return {
        ...state,
        adhocTaskList: action.adhocTaskList,
        adhocLoaded: action.adhocLoaded,
        adhocModelLoaded: action.adhocModelLoaded,
      };
    case "FETCH_ADHOC_TASK":
      console.log("fetchAdhoc1", action.adhocTask);
      const temp = changeG(action.adhocTask);
      console.log("fetchadhoc", action.adhocTask, temp);
      return {
        ...state,
        adhocTask: temp,
        adhocModelLoaded: action.adhocModelLoaded,
      };
    case "PERSIST_ADHOC_TASK":
      return {
        ...state,
        adhocTask: { url: [] },
        adhocSuccess: action.adhocSuccess,
      };
    case "UPDATE_ADHOC_TASK":
      return {
        ...state,
        adhocTask: action.adhocTask,
      };
    case "UPDATE_MODEL_LOADED":
      return {
        ...state,
        adhocModelLoaded: action.adhocModelLoaded,
      };
    case "UPDATE_ADHOC_MODEL_NULL":
      return {
        ...state,
        adhocTask: action.adhocTask,
      };

    default:
      return state;
  }
};

function changeG(data) {
  let temp = data;
  console.log("temp", temp);
  if (temp["url"] !== undefined) {
    temp["url"] = JSON.parse(temp["url"]);
  } else {
    temp.transactions[0].url = JSON.parse(temp.transactions[0].url);
  }

  console.log("temp2", temp);
  return temp;
}

function changeDataFormat(data) {
  console.log("console 1", data);
  let course = {};
  let quiz = {};
  let task = {};
  let courseTotal = 0;
  let taskTotal = 0;
  let quizTotal = 0;
  let courseDetails = [];
  let taskDetails = [];
  let quizDetails = [];
  let courseCount = 0;
  let quizCount = 0;
  let taskCount = 0;
  data.map((row) => {
    console.log("console 2", row);
    if (row.type === "Course" || row.type === "course") {
      courseCount = courseCount + 1;
      // let details = {};
      courseTotal = courseTotal + Number(row.earning);
      // details["date"] = row.date;
      // details["earning"] = row.earnings;
      //courseDetails.push(details);
      courseDetails.push(row);
    }
    if (row.type === "Quiz" || row.type === "quiz") {
      quizCount = quizCount + 1;
      //quiz["totalcount"] = quizCount;
      //quiz["type"] = "Quiz";
      //let details = {};
      quizTotal = quizTotal + Number(row.earning);
      // details["date"] = row.date;
      // details["earning"] = row.earnings;
      // quizDetails.push(details);
      quizDetails.push(row);
    }
    if (row.type === "Task" || row.type === "task") {
      taskCount = taskCount + 1;
      //task["totalcount"] = taskCount;
      task["type"] = "Task";
      //let details = {};
      taskTotal = taskTotal + Number(row.earning);
      // details["date"] = row.date;
      // details["earning"] = row.earnings;
      // console.log("console 4",details)
      // taskDetails.push(details);
      taskDetails.push(row);
    }
  });
  //   console.log("console 3",courseDetails,quizDetails,taskDetails);
  //   let courseGroup = {};
  //   courseDetails.forEach(function(val){
  //     var date1 = val.date
  //     if(date1 in courseGroup){
  //       courseGroup[date1].push(val.earning);
  //     }
  //     else{
  //       courseGroup[date1] = new Array(val.earning);
  //     }
  //   })
  //   let taskGroup = {};
  //   taskDetails.forEach(function(val){
  //     var date1 = val.date
  //     if(date1 in taskGroup){
  //       taskGroup[date1].push(val.earning);
  //     }
  //     else{
  //       taskGroup[date1] = new Array(val.earning);
  //     }
  //   })
  //   let quizGroup = {};
  //   quizDetails.forEach(function(val){
  //     var date1 = val.date
  //     if(date1 in quizGroup){
  //       quizGroup[date1].push(val.earning);
  //     }
  //     else{
  //       quizGroup[date1] = new Array(val.earning);
  //     }
  //   })
  //   function add(accumulator, a) {
  //     return accumulator + parseInt(a);
  //   }
  //   let arrCourse = new Array();
  // for (var key of Object.keys(courseGroup)) {
  //    var test = {};
  //   test["date"]=key;
  //   test["count"] = courseGroup[key].length;
  //   test["earning"] = courseGroup[key].reduce(add, 0);
  //   arrCourse.push(test);
  // }
  // let arrQuiz = new Array();
  // for (var key of Object.keys(quizGroup)) {
  //    var test = {};
  //   test["date"]=key;
  //   test["count"] = quizGroup[key].length;
  //   test["earning"] = quizGroup[key].reduce(add, 0);
  //   arrQuiz.push(test);
  // }
  // let arrTask = new Array();
  // for (var key of Object.keys(taskGroup)) {
  //    var test = {};
  //   test["date"]=key;
  //   test["count"] = taskGroup[key].length;
  //   test["earning"] = taskGroup[key].reduce(add, 0);
  //   arrTask.push(test);
  // }
  course["type"] = "Course";
  course["totalEarning"] = courseTotal;
  course["totalCount"] = courseCount;
  course["details"] = courseDetails;
  quiz["type"] = "Quiz";
  quiz["totalEarning"] = quizTotal;
  quiz["totalCount"] = quizCount;
  quiz["details"] = quizDetails;
  task["type"] = "Task";
  task["totalEarning"] = taskTotal;
  task["totalCount"] = taskCount;
  task["details"] = taskDetails;
  console.log("after update", course, task, quiz);
  var finalArray = new Array();
  if (course !== undefined) {
    finalArray.push(course);
  }
  if (quiz !== undefined) {
    finalArray.push(quiz);
  }
  if (task !== undefined) {
    finalArray.push(task);
  }
  console.log("finalArray", finalArray);
  return finalArray;
}

function filterEmails(data) {
  const filteredEmails = data.filter(
    (row) => dateDifference(row.date, new Date()) < 3
  );
  return filteredEmails;
}

export default InboxReducer;

import { ConstructionOutlined } from "@material-ui/icons";
import axios from "axios";
import { Api, ApiKey } from "../ApiCredencial";
import { FILTER_INBOX } from "../constants";
import { AZ_TASK_MANAGER_API, AZ_TASK_MANAGER_HEADERS } from "../tasks/ApiCred";
import { AZ_ADMIN_API, AZ_ADMIN_HEADERS } from "../users/ApiCred";

export const inboxSearch = (searchTerm) => ({
  type: FILTER_INBOX,
  searchTerm,
});

let inb = {
  data: [
    {
      row_no: 1,
      id: 23,
      name: "Bootcamp",
      type: "Course",
      description: "New Joinee Bootcamp",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "10:00 AM",
      user: "None",
      comments: null,
    },
    {
      row_no: 2,
      id: 27,
      name: "Discipline Management",
      type: "Quiz",
      description: "Discipline Managment Questions",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Krishna",
      kitchen: "HSR",
      day: "Sunday",
      time: "12:00 PM",
      user: "Mohammed Ali #852",
      comments: "done",
    },
    {
      row_no: 3,
      id: 29,
      name: "Process Management",
      type: "Quiz",
      description: "Process Managment Questions",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Krishna",
      kitchen: "HSR",
      day: "Sunday",
      time: "3:00 PM",
      user: "Mohammed Ali #852",
      comments: "done",
    },
    {
      row_no: 4,
      id: 30,
      name: "Closing Checklist",
      type: "Task",
      description: "Closing Checklist",
      task_level: "Kitchen",
      url: "https://docs.google.com/forms/d/e/1FAIpQLScrhpVt_-fiJB4n5eHN_xGUarWHhkdtiHLeJIn_DKdmQy3KvQ/viewform",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "10:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 5,
      id: 31,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "12:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 6,
      id: 32,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "3:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 7,
      id: 33,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "6:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 8,
      id: 34,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "9:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 9,
      id: 35,
      name: "Review OOS & Perform Stock In",
      type: "Task",
      description: "Review OOS & Perform Stock In in KAC",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "11:00 PM",
      user: "None",
      comments: null,
    },
    {
      row_no: 10,
      id: 38,
      name: "Check Restaurant Status in Aggregator",
      type: "Task",
      description:
        "Check Restaurant Status in Aggregator and report the anomaly",
      task_level: "Kitchen",
      url: "",
      status: "Open",
      created_by: "Shanil",
      kitchen: "HSR",
      day: "Sunday",
      time: "1:00 PM",
      user: "None",
      comments: null,
    },
  ],
  total_rows: 11,
};

export const fetchInbox = (location_id) => (dispatch) => {
  //console.log("Location Id", location_id);
  const userId = window.sessionStorage.getItem("userId");
  // console.log(userId, "userISd");
  const url = `${AZ_TASK_MANAGER_API}/inbox/user?id=${userId}&status=open`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      dispatch({
        type: "FETCH_INBOX",
        inbox: response.data,
      });
    })
    .catch((err) => err);
};

export const openInboxItem = (
  itemid,
  itemtype,
  itemkitchen,
  mainItem,
  programId
) => ({
  type: "OPEN_INBOX_ITEM",
  itemid,
  itemtype,
  itemkitchen,
  mainItem,
  programId,
});
export const fetchComplete = () => (dispatch) => {
  const userId = window.sessionStorage.getItem("userId");
  const location_id = window.sessionStorage.getItem("locAtr");
  const url = `${AZ_TASK_MANAGER_API}/inbox/user?id=${userId}&location=${location_id}&startDate=&endDate=&status=done`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      //console.log("complete response", response);
      dispatch({
        type: "FETCH_COMPLETE",
        complete: response.data,
      });
    })
    .catch((err) => err);
};

export const fetchUserEarning = (from, to) => (dispatch) => {
  const userId = window.sessionStorage.getItem("userId");
  const tenantId = window.sessionStorage.getItem("tenantId");
  const url = `${AZ_ADMIN_API}/api/earnings-admin-user?tenant_id=${tenantId}&startDate=${from}&endDate=${to}&user_id=${userId}`;
  axios
    .get(url, { headers: { ...AZ_ADMIN_HEADERS } })
    .then((response) => {
      console.log("complete response", response.data);
      dispatch({
        type: "FETCH_USER_EARNING",
        earning: response.data.data,
      });
    })
    .catch((err) => err);
};

export const fetchAdhocTasksList = () => (dispatch) => {
  const user_id = window.sessionStorage.getItem("userId");
  const url = `${AZ_TASK_MANAGER_API}/adhoc-task?user_id=${user_id}&status='open'`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("response", response);
      dispatch({
        type: "FETCH_ADHOC_TASK_LIST",
        adhocTaskList: response.data,
        adhocLoaded: true,
        adhocModelLoaded: true,
      });
    })
    .catch((err) => err);
};

export const fetchAdhocTask = (id) => (dispatch) => {
  const url = `${AZ_TASK_MANAGER_API}/adhoc-task/${id}`;
  if (id === -1) {
    dispatch({
      type: "FETCH_ADHOC_TASK",
      adhocTask: {
        id: "-1",
        tenant_id: window.sessionStorage.getItem("tenantId"),
        date_time: "",
        user_id: window.sessionStorage.getItem("userId"),
        url: "[]",
      },
      adhocModelLoaded: false,
    });
  } else {
    axios
      .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
      .then((response) => {
        console.log("response", response);
        dispatch({
          type: "FETCH_ADHOC_TASK",
          adhocTask: response.data,
          adhocModelLoaded: false,
        });
      })
      .catch((err) => err);
  }
};

export const persistAdhocTask = (adhoc) => (dispatch) => {
  let a = adhoc;
  const url = `${AZ_TASK_MANAGER_API}/adhoc-task/${adhoc.id}`;
  //const url = "";
  console.log("Adhoc", adhoc);
  axios
    .post(url, adhoc, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("response", response);
      dispatch({
        type: "PERSIST_ADHOC_TASK",
        adhocTask: { url: [] },
        adhocSuccess: response.data.response,
      });
    })
    .catch((error) => error);
};
export const updateAdhocTask = (adhoc, attr, value) => (dispatch) => {
  //console.log("adhoc", adhoc, attr, value);
  let a = JSON.parse(JSON.stringify(adhoc));
  a[attr] = value;
  //console.log("adhoc 2", a);
  dispatch({
    type: "UPDATE_ADHOC_TASK",
    adhocTask: a,
  });
};
export const updateAdhocModelLoaded = () => (dispatch) => {
  dispatch({
    type: "UPDATE_MODEL_LOADED",
    adhocModelLoaded: true,
  });
};

export const updateAdhocModelToNull = () => (dispatch) => {
  dispatch({
    type: "UPDATE_ADHOC_MODEL_NULL",
    adhocTask: { url: [] },
  });
};
export const earningConfig = () => (dispatch) => {
  //console.log("called action for config");
  const url = `${AZ_TASK_MANAGER_API}/config/earnings`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      //console.log("response", response);
      dispatch({
        type: "EARNING_CONFIG",
        config: response.data,
      });
    })
    .catch((err) => err);
};

const INIT_STATE = {
  task: {
    name: "",
    type: "Weekly",
    description: "",
    level: "Corporate",
    url: "",
    status: "Open",
    created_by: "",
    kitchen: [],
    day: [],
    time: "00:00",
    user: "",
    comments: "",

    locations: [{ kitchen: "Arekere", city: "Bangalore" }],
  },
  tasks: [],
  sopList: [],
  loaded: true,
  success: "",
  sopLoaded: true,
  sop: {
    questions: [],
  },
  sopInList: { questions: [] },
  currentSopQues: {
    image: "false",
    type: "Radio",
    answer_options: "Yes,No,Not Applicable",
  },
  aiSOPAttribute: {},
  aiLoaded: true,
  aiSOPs: [],
  aiSOPSuccess: "",
};

const taskTypeList = [
  {
    value: "Reference URL",
    valueId: 0,
  },
  {
    value: "SOPs",
    valueId: 1,
  },
  {
    value: "Audit",
    valueId: 2,
  },
];

const TaskReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_TASK":
      let task = action.task;
      //console.log(task.time);
      task.time = new Date("1 Jan 1900 " + task.time);
      if (task.type === "Weekly") {
        task.date = new Date();
      }
      if (task.id !== undefined) {
        // task["taskType"] = taskTypeList.filter((row) => {
        //   return row.valueId == task.task_type;
        // });
        // task["SOP"] = state.sopList.filter((row) => {
        //   return row.id == task.url;
        // });
        taskTypeList.map((row) => {
          if (row.valueId == task.task_type) {
            task["taskType"] = row;
          }
        });
        state.sopList.map((row) => {
          if (row.id == task.url) {
            task["SOP"] = row;
          }
        });
      }
      if (task.task_type === undefined) {
        task["taskType"] = {
          value: "Reference URL",
          valueId: 0,
        };
      }

      return {
        ...state,
        task: action.task,
        loaded: action.loaded,
        success: "",
      };

    case "UPDATE_TASK":
      //console.log("Task reducer called.");
      return {
        ...state,
        task: action.task,
        loaded: true,
        success: "",
      };

    case "PERSIST_TASK":
      let task1 = action.task;
      task1.time = new Date("1 Jan 1900 " + task1.time);
      task1.day = [{ title: "Monday", value: 0 }];
      if (task1.type === "Weekly") {
        task1.date = new Date();
      }
      return {
        ...state,
        task: task1,
        loaded: true,
        success: action.success,
      };

    case "FETCH_TASKS":
      return {
        ...state,
        tasks: action.tasks.data,
        loaded: action.loaded,
        success: "",
      };
    case "FETCH_USER_TASK":
      //console.log("task", action.userTask);
      const tempUserTask = changeSOPAnswerUrl(action.userTask);
      return {
        ...state,
        userTask: tempUserTask,
        loaded: true,
        success: "",
      };
    case "PERSIST_USER_TASK":
      return {
        ...state,
        userTask: action.userTask,
        loaded: true,
        success: "",
      };
    case "UPDATE_USER_TASK":
      //console.log("Task reducer called.");
      return {
        ...state,
        userTask: action.userTask,
        loaded: true,
        success: "",
      };
    case "FETCH_SOPS":
      //console.log("sop reducer called.");
      return {
        ...state,
        sopList: action.sopList,
        sopLoaded: action.sopLoaded,
      };
    case "FETCH_SOP":
      return {
        ...state,
        sop: action.sop,
      };
    case "FETCH_SOP_LIST":
      return {
        ...state,
        sopInList: action.sopInList,
      };
    case "PERSIST_SOP":
      return {
        ...state,
        sop: action.sop,
        sopInList: {},
        sopSuccess: action.sopSuccess,
      };
    case "UPDATE_SOP":
      return {
        ...state,
        sop: action.sop,
      };
    case "CURRENT_SOP_QUES":
      return {
        ...state,
        currentSopQues: action.currentSopQues,
      };
    case "UPDATE_SOP_QUESTION":
      return {
        ...state,
        sop: action.sop,
        currentSopQues: action.currentSopQues,
      };
    case "EDIT_CURRENT_SOP_QUES":
      return {
        ...state,
        sop: action.sop,
      };
    case "UPDATE_SUCCESS_TASK":
      return {
        ...state,
        success: action.success,
      };
    case "UPDATE_SUCCESS_SOP":
      return {
        ...state,
        sopSuccess: action.sopSuccess,
      };
    case "DOWNLOAD_SOP_TEMPLATE":
      return {
        ...state,
        downSopData: action.downSopData,
      };
    case "UPDATE_AI_SOP_ATTRIBUTE":
      return {
        ...state,
        aiSOPAttribute: action.aiSOPAttribute,
      };
    case "FETCH_AI_SOP":
      return {
        ...state,
        aiSOPs: action.aiSOPs,
        aiLoaded: action.aiLoaded,
        aiSOPSuccess: action.aiSOPSuccess,
        aiSOPAttribute: {},
      };
    case "UPDATE_AI_SOP":
      return {
        ...state,
        aiSOPs: action.aiSOPs,
        aiLoaded: true,
      };
    case "UPDATE_AI_SOP_EDIT":
      return {
        ...state,
        aiSOPs: action.aiSOPs,
      };
    default:
      return state;
  }
};

export default TaskReducer;

const changeSOPAnswerUrl = (data) => {
  if (data.task_type == 1) {
    data.sop.map((row, index) => {
      //console.log(data.sop[index].answer_url);
      data.sop[index].answer_url = JSON.parse(data.sop[index].answer_url);
    });
  }

  return data;
};
